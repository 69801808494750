// Components/WebProfil/Pages/NotFound.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <section className="error-wrap-style-1">
      <div className="container">
        <div className="error-box">
          <div className="figure-box">
            <img
              src="/webprofil-template/media/banner/404.jpg"
              alt="404"
              width="668"
              height="418"
            />
          </div>
          <div className="content-box">
            <h2 className="title">Oops! That page can't be found.</h2>
            <p className="description">
              Try using the button below to go to main page of the site
            </p>
            <Link to="/" className="btn-fill">Back to Home Page</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getStorageUrl, getImageProps } from '../../helpers/storageHelper';
import { useSettings } from '../../helpers/useSettings';
import { useSeo } from '../../helpers/useSeo';  
import Sidebar from '@/Components/WebProfil/Layout/Sidebar';

const CategoryPosts = () => {
  const { slug } = useParams();
  const [page, setPage] = React.useState(1);
  const perPage = 10;
  const { getSettingValue } = useSettings();

  const { data: categoryData } = useQuery({
    queryKey: ['category', slug],
    queryFn: async () => {
      const response = await fetch(`/api/v1/posts/categories`);
      const data = await response.json();
      return data.data.find(cat => cat.post_kat_slug === slug);
    }
  });

  const { data: postsData, isLoading, error } = useQuery({
    queryKey: ['posts', slug, page],
    queryFn: async () => {
      const response = await fetch(`/api/v1/posts?category=${categoryData?.id}&page=${page}&per_page=${perPage}&sort_by=post_tanggal&sort_order=desc`);
      return response.json();
    },
    enabled: !!categoryData?.id
  });

  const posts = postsData?.data?.data || [];
  const pagination = postsData?.data;

  // Generate meta description from the first few posts
  const generateMetaDescription = () => {
    if (!posts.length) {
      return `Berita dan artikel dalam kategori ${categoryData?.post_kat_nama || ''}`;
    }
    
    const postTitles = posts
      .slice(0, 3)
      .map(post => post.post_judul.replace(/<[^>]+>/g, ''))
      .join(', ');
      
    return `Baca berita terbaru tentang ${categoryData?.post_kat_nama}: ${postTitles} dan artikel ${categoryData?.post_kat_nama} lainnya di ${getSettingValue('system.app_name')}`;
  };

  // Generate keywords from posts and category
  const generateKeywords = () => {
    const baseKeywords = [
      categoryData?.post_kat_nama,
      `berita ${categoryData?.post_kat_nama}`,
      `artikel ${categoryData?.post_kat_nama}`,
      getSettingValue('system.app_name'),
      'berita terbaru'
    ].filter(Boolean);

    const postKeywords = posts
      .slice(0, 5)
      .flatMap(post => post.post_judul.replace(/<[^>]+>/g, '').split(' '))
      .filter(word => word.length > 3);

    return [...new Set([...baseKeywords, ...postKeywords])].join(', ');
  };
 
  useSeo({
    title: `Kategori ${categoryData?.post_kat_nama || ''} - Halaman ${page}`,
    description: generateMetaDescription(),
    keywords: generateKeywords(),
    type: 'website',
    image: posts[0]?.post_gambar 
      ? getStorageUrl(`posts/${posts[0].post_gambar}`) 
      : getSettingValue('system.meta_image'),
    author: getSettingValue('system.meta_author'),
    publishedTime: posts[0]?.post_tanggal,
    modifiedTime: posts[posts.length - 1]?.post_tanggal,
    section: 'Kategori',
    tags: posts.slice(0, 5).map(post => post.kategori.post_kat_nama),
    locale: 'id_ID'
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading posts</div>;
  
  const getImagePath = (path) => {
    return `../${path}`;
  }

  return (
    <>
      <div
        className="breadcrumb-wrap-style-2"
        style={{ backgroundImage: `url(${getImagePath(getSettingValue('system.bg_post'))})` }}
      >
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/berita">Berita</Link></li>
            <li className="breadcrumb-item active" aria-current="page">
              Kategori berita
            </li>
          </ol>
        </nav>
        <div className="inner-banner-title">
          <h1 className="title">Kategori "<i>{categoryData?.post_kat_nama}</i>"</h1>
        </div>
      </div>

      <section className="blog-wrap-style-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                {posts.length > 0 ? (
                  posts.map((post) => (
                    <div className="col-12" key={post.id}>
                      <div className="blog-box style-4">
                        <div className="figure-box">
                          <Link to={`/berita/${post.post_slug}`}>
                            <img
                              {...getImageProps(`posts/${post.post_gambar}`, {
                                  alt: post.post_judul,
                                  className: "img-fluid",
                                  defaultImage: '/default-image.jpg'
                              })}
                            />
                          </Link>
                        </div>
                        <div className="content-box">
                          <Link to={`/kategori/${post.kategori.post_kat_slug}`} className="category-name">
                            {post.kategori.post_kat_nama}
                          </Link>
                          <h3 className="title">
                            <Link to={`/berita/${post.post_slug}`}>
                              {post.post_judul}
                            </Link>
                          </h3>
                          <ul className="entry-meta inline-list">
                            <li>
                              <a href="#"><i className="far fa-user"></i>Admin</a>
                            </li>
                            <li>
                              <i className="far fa-calendar-alt"></i>
                              {new Date(post.post_tanggal).toLocaleDateString('id-ID', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </li>
                            <li><i className="far fa-eye"></i>{post.post_views} views</li>
                            <li>
                              <Link to={`/berita/${post.post_slug}#disqus_thread`}>
                                <i className="far fa-comments"></i>0 komentar
                              </Link>
                            </li>
                          </ul>
                          <p className="description">
                            {post.post_isi.replace(/<[^>]+>/g, '').slice(0, 150)}...
                          </p>
                          <Link to={`/berita/${post.post_slug}`} className="btn-text style-4">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <div className="alert alert-danger">Data tidak ditemukan</div>
                  </div>
                )}
              </div>

              {pagination && pagination.last_page > 1 && (
                <div className="pagination-style-1">
                  <ul className="pagination">
                    <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                      <button
                        className="page-link"
                        onClick={() => setPage(page - 1)}
                        disabled={page === 1}
                      >
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </button>
                    </li>
                    
                    {[...Array(pagination.last_page)].map((_, index) => (
                      <li
                        key={index + 1}
                        className={`page-item ${page === index + 1 ? 'active' : ''}`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setPage(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}

                    <li className={`page-item ${page === pagination.last_page ? 'disabled' : ''}`}>
                      <button
                        className="page-link"
                        onClick={() => setPage(page + 1)}
                        disabled={page === pagination.last_page}
                      >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
             <Sidebar/>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryPosts;
// helpers/textHelpers.js

/**
 * Removes HTML tags and truncates text to specified word limit
 * @param {string} html - The HTML string to be cleaned
 * @param {number} wordLimit - Number of words to keep (default: 50)
 * @param {string} ending - String to append after truncation (default: '...')
 * @returns {string} - The truncated text content without HTML tags
 */
export const truncateHtmlText = (html, wordLimit = 50, ending = '...') => {
    if (!html) return '';

    // Handle case when input is not a string
    if (typeof html !== 'string') {
        return String(html);
    }

    // Remove HTML tags first
    const temp = document.createElement('div');
    temp.innerHTML = html;
    const plainText = temp.textContent || temp.innerText || '';

    // Split into words, slice to limit, and rejoin
    const words = plainText.split(/\s+/);
    if (words.length <= wordLimit) {
        return plainText.trim();
    }

    return words.slice(0, wordLimit).join(' ').trim() + ending;
};

/**
 * Alternative implementation using regex
 */
export const truncateHtmlTextRegex = (html, wordLimit = 50, ending = '...') => {
    if (!html) return '';
    
    if (typeof html !== 'string') {
        return String(html);
    }

    // Remove HTML tags and clean up spacing
    const plainText = html
        .replace(/<[^>]*>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();

    const words = plainText.split(/\s+/);
    if (words.length <= wordLimit) {
        return plainText;
    }

    return words.slice(0, wordLimit).join(' ') + ending;
};
import React from 'react';
import { useSettings } from '../helpers/useSettings';
import { getImageProps } from '../helpers/storageHelper';

const BrandSection = () => {
  const { links } = useSettings();
  
  // Filter links dengan type 'file'
  const fileLinks = links.filter(link => link.type === 'file');

  return (
    <section className="brand-wrap-style-1">
      <div className="container">
        <div className="section-heading style-1">
          <h2 className="title">Partner Kami</h2>
        </div>
        <div 
          className="inner-wrap" 
          style={{ backgroundImage: "url('/media/elements/element12.png')" }}
        >
          <div className="row justify-content-center align-items-center">
            {fileLinks.map((link) => (
              <div
                key={link.id}
                className="col-xxl-5 col-md-3 col-sm-4 col-6 wow fadeInRight animated"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <div className="brand-box style-1">
                  <div className="figure-box">
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      <img
                        {...getImageProps(`links/${link.gambar}`, {
                          alt: link.nama_link,
                          width: 135,
                          height: 135,
                          defaultImage: 'https://placehold.co/135x135'
                        })}
                      />
                    </a>
                    <span className="brand-name">{link.nama_link}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandSection;
  import React from 'react';
  import { Link } from 'react-router-dom';
  import Menu from './Menu';
  import { useSettings } from '../helpers/useSettings';

  const Header = () => {
    const { getSettingValue, institution ,settingsData} = useSettings();
  console.log(getSettingValue('system'));
    return (
      <header className="header-style-1 sticky-on">
        <div id="sticky-placeholder"></div>
        <div id="topbar-wrap" className="topbar style-1">
          <div className="container">
            <div className="topbar-row">
              <ul className="left-box inline-list align-items-center">
                <li>
                  <div className="dropdown language-select">
                    {getSettingValue('system.translate') && (
                      <div id="google_translate_element"></div>
                    )}
                  </div>
                </li>
                <li>
                  <i className="fas fa-phone-alt"></i> {institution.telepon}
                </li>
                <li>
                  <i className="fas fa-map-marker-alt"></i> {`${institution.jalan}, ${institution.kelurahan}`}
                </li>
              </ul>
              
              <ul className="right-box inline-list">
                <li className="d-none d-xl-block">
                  <div className="social align-items-center">
                    <a href={getSettingValue('system.facebook_link')} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href={getSettingValue('system.twitter_link')} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href={getSettingValue('system.instagram_link')} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href={getSettingValue('system.youtube_link')} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-youtube"></i>
                    </a>
                    <a href={getSettingValue('system.tiktok_link')} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-tiktok"></i>
                    </a>
                  </div>
                </li>
                <li className="action-btn align-items-center">
                <a href="/login">Log In</a> 
                <a href={getSettingValue('system.link_penmaru')}>Daftar</a>  
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="navbar-wrap" className="navbar-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="site-branding">
                <Link to="/" className="logo">
                  <img
                    src={getSettingValue('system.app_logo')}
                    alt="Logo"
                    width="340"
                  />
                </Link>
              </div>

              <Menu />

              <ul className="header-action-items inline-list">
                <li className="action-wrap">
                  <Link to={getSettingValue('system.link_penmaru')} className="btn-fill style-3">
                    {getSettingValue('pmb.pmb_name')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  };

  export default Header;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSettings } from '../helpers/useSettings';
import { getImageProps } from '../helpers/storageHelper';

const StudyProgramsSection = () => {
  const { studyPrograms } = useSettings();
  const [activeFilter, setActiveFilter] = useState('all');
  const [visibleItems, setVisibleItems] = useState(6);
  
  // Group programs by education level for filtering
  const jenjangGroups = studyPrograms.reduce((acc, program) => {
    if (!acc[program.id_jenjang_pendidikan]) {
      acc[program.id_jenjang_pendidikan] = {
        id: program.id_jenjang_pendidikan,
        name: program.nama_jenjang_pendidikan
      };
    }
    return acc;
  }, {});

  const handleFilterClick = (filterId) => {
    setActiveFilter(filterId);
    setVisibleItems(6); // Reset visible items when filter changes
  };

  const handleLoadMore = () => {
    setVisibleItems(prev => prev + 6);
  };

  // Filter programs based on active filter
  const filteredPrograms = studyPrograms.filter(
    program => activeFilter === 'all' || program.id_jenjang_pendidikan === activeFilter
  );

  // Check if there are more items to load
  const hasMore = filteredPrograms.length > visibleItems;

  return (
    <section className="course-wrap-style-1">
      <div className="container">
        <div className="section-heading style-1">
          <h2 className="title">Program Studi</h2>
        </div>
        <div className="isotope-wrap">
          <div className="isotope-classes-tab isotop-btn-layout1">
            <button
              onClick={() => handleFilterClick('all')}
              className={`nav-item ${activeFilter === 'all' ? 'current' : ''}`}
            >
              Semua Program
            </button>
            {Object.values(jenjangGroups).map((jenjang) => (
              <button
                key={jenjang.id}
                onClick={() => handleFilterClick(jenjang.id)}
                className={`nav-item ${activeFilter === jenjang.id ? 'current' : ''}`}
              >
                {jenjang.name}
              </button>
            ))}
          </div>

          <div className="row featuredContainer">
            {filteredPrograms
              .slice(0, visibleItems)
              .map((program) => (
                <div
                  key={program.id}
                  className={`col-xl-4 col-md-6 col-12 all ${program.id_jenjang_pendidikan}`}
                >
                  <div className="course-box style-1">
                    <div className="figure-box">
                      <img
                        {...getImageProps(program.logo_prodi, {
                          alt: program.nama_program_studi,
                          width: 500,
                          height: 273,
                          defaultImage: 'https://placehold.co/500x273'
                        })}
                      />
                    </div>
                    <div className="content-box">
                      <div className="category-name color-1">{program.nama_jenjang_pendidikan}</div>
                      <h3 className="title">
                        {program.nama_program_studi}
                      </h3>
                      {program.about_prodi && (
                        <h4 className="sub-title">{program.about_prodi}</h4>
                      )}
                      <ul className="inline-list course-feature">
                        {program.akreditasi && (
                          <li>
                            <i className="fas fa-certificate"></i>
                            Akreditasi {program.akreditasi}
                          </li>
                        )}
                        {program.tanggal_sk && (
                          <li>
                            <i className="fas fa-calendar"></i>
                            {program.tanggal_sk}
                          </li>
                        )}
                      </ul>
                      <div className="course-footer">
                        {program.sk_penyelenggara && (
                          <div className="course-fee">SK : {program.sk_penyelenggara}</div>
                        )}
                      </div>
                    </div>
                    <div className="hover-content">
                      <div className="inner">
                        <p className="description">
                          {program.about_prodi}
                        </p>
                        <div className="course-footer">
                          <Link
                            to={`/prodi/${program.id}`}
                            className="btn-fill style-4"
                          >
                            Lihat Detail
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
          </div>

          {hasMore && (
            <div className="text-center mt-4">
              <button 
                onClick={handleLoadMore}
                className="btn-fill style-4"
              >
                Muat Lebih Banyak
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default StudyProgramsSection;
import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSettings } from '../../helpers/useSettings';
import { useSeo } from '../../helpers/useSeo';
import { getStorageUrl, getImageProps } from '../../helpers/storageHelper';
import Sidebar from '@/Components/WebProfil/Layout/Sidebar';

const PostDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { getSettingValue } = useSettings();

  const { data: postData, isLoading, isError } = useQuery({
    queryKey: ['post', slug],
    queryFn: async () => {
      const response = await fetch(`/api/v1/posts/${slug}`);
      if (!response.ok) {
        throw new Error('Post not found');
      }
      return response.json();
    }
  });

  useEffect(() => {
    if (isError || postData?.status === 'error') {
      navigate('/notfound');
      return;
    }
  }, [isError, postData, navigate]);
 
  const post = postData?.data;

  // Generate clean description from post content
  const generateDescription = (content) => {
    if (!content) return '';
    // Remove HTML tags and extra whitespace
    const cleanText = content
      .replace(/<[^>]+>/g, '')
      .replace(/\s+/g, ' ')
      .trim();
    // Get first few sentences, up to 160 characters
    return cleanText.split('. ')
      .reduce((acc, sentence) => {
        if (acc.length + sentence.length < 155) {
          return acc + (acc ? '. ' : '') + sentence;
        }
        return acc;
      }, '') + '...';
  };

  // Generate keywords from post content and category
  const generateKeywords = (post) => {
    if (!post) return '';

    const baseKeywords = [
      post.kategori?.post_kat_nama,
      `berita ${post.kategori?.post_kat_nama}`,
      'artikel',
      getSettingValue('system.app_name')
    ].filter(Boolean);

    // Extract meaningful words from title and content
    const contentWords = (post.post_judul + ' ' + post.post_isi)
      .replace(/<[^>]+>/g, '')
      .split(/\s+/)
      .filter(word => word.length > 3)
      .slice(0, 10); // Take first 10 meaningful words

    return [...new Set([...baseKeywords, ...contentWords])].join(', ');
  };

  // Generate structured data for article
  const generateTags = (post) => {
    if (!post) return [];
    return [
      post.kategori?.post_kat_nama,
      'berita',
      'artikel',
      ...post.post_judul.split(' ').filter(word => word.length > 3).slice(0, 5)
    ].filter(Boolean);
  };

  // Enhanced SEO implementation
  useSeo({
    title: post?.post_judul,
    description: generateDescription(post?.post_isi),
    keywords: generateKeywords(post),
    type: post?.post_jenis === 'page' ? 'website' : 'article',
    image: post?.post_gambar 
      ? getStorageUrl(`posts/${post.post_gambar}`)
      : getSettingValue('system.meta_image'),
    author: getSettingValue('system.meta_author'),
    publishedTime: post?.post_tanggal,
    modifiedTime: post?.updated_at || post?.post_tanggal,
    section: post?.kategori?.post_kat_nama || 'Uncategorized',
    tags: generateTags(post),
    locale: 'id_ID'
  });

  // Disqus integration
  useEffect(() => {
    if (post && post.post_jenis !== 'page') {
      window.disqus_config = function() {
        this.page.url = window.location.href;
        this.page.identifier = `post-${post.id}`;
        this.page.title = post.post_judul;
      };

      const script = document.createElement('script');
      script.src = 'https://azzr.disqus.com/embed.js';
      script.setAttribute('data-timestamp', +new Date());
      document.body.appendChild(script);

      return () => {
        // Cleanup Disqus on unmount
        const disqusThread = document.getElementById('disqus_thread');
        if (disqusThread) {
          while (disqusThread.firstChild) {
            disqusThread.removeChild(disqusThread.firstChild);
          }
        }
        // Remove the Disqus script
        const scripts = document.getElementsByTagName('script');
        for (let script of scripts) {
          if (script.src.includes('disqus.com')) {
            script.remove();
          }
        }
      };
    }
  }, [post]);

  if (isLoading) return <div>Loading...</div>;
  if (!post) return null;

  const getImagePath = (path) => {
    return `../${path}`;
  }

  const isPage = post.post_jenis === 'page';

  return (
    <>
      <div
        className="breadcrumb-wrap-style-2"
        style={{ backgroundImage: `url(${getImagePath(getSettingValue('system.bg_post'))})` }}
      >
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            {!isPage && post.kategori && (
              <>
                <li className="breadcrumb-item">
                  <Link to="/berita">Berita</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/kategori/${post.kategori.post_kat_slug}`}>
                    {post.kategori.post_kat_nama}
                  </Link>
                </li>
              </>
            )}
          </ol>
        </nav>
        <div className="inner-banner-title">
          <h1 className="title">{post.post_judul}</h1>
        </div>
      </div>

      <section className="single-blog-wrap">
        <div className="container">
          <div className="row"> 
            <div className={isPage ? 'col-12' : 'col-lg-8'}>
              {post.post_gambar && (
                <div className="main-figure-box">
                  <img
                    {...getImageProps(`posts/${post.post_gambar}`, {
                      alt: post.post_judul,
                      defaultImage: '/default-image.jpg'
                    })}
                  />
                  {!isPage && post.kategori && (
                    <Link 
                      to={`/kategori/${post.kategori.post_kat_slug}`} 
                      className="category-name"
                    >
                      {post.kategori.post_kat_nama}
                    </Link>
                  )}
                </div>
              )}

              <div className="blog-content-box" style={{ padding: 0 }}>
                <h2 className="main-title">{post.post_judul}</h2>
                {!isPage && (
                  <ul className="entry-meta inline-list">
                    <li>
                      <a href="#"><i className="far fa-user mr-2"></i>Admin</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt mr-2"></i>
                      {new Date(post.post_tanggal).toLocaleDateString('id-ID', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </li>
                    <li>
                      <i className="far fa-eye mr-2"></i>
                      {post.post_views} views
                    </li>
                    <li>
                      <a href="#disqus_thread">
                        <i className="far fa-comments mr-2"></i>
                        0 komentar
                      </a>
                    </li>
                  </ul>
                )}

                <p 
                  className="description"
                  dangerouslySetInnerHTML={{ __html: post.post_isi }}
                />

                {!isPage && (
                  <>
                    <div id="disqus_thread"></div>
                    <noscript>
                      Please enable JavaScript to view the{' '}
                      <a href="https://disqus.com/?ref_noscript">
                        comments powered by Disqus.
                      </a>
                    </noscript>
                  </>
                )}
              </div>
            </div>

            {/* Only render Sidebar for non-page posts */}
            {!isPage && <Sidebar />}
          </div>
        </div>
      </section>
    </>
  );
};

export default PostDetail;
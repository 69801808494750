import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSettings } from '../helpers/useSettings';

const Menu = () => {
  const { links, studyPrograms } = useSettings();
  const [activeMenus, setActiveMenus] = useState(new Set());

  // Fetch menu data
  const { data: menuData } = useQuery({
    queryKey: ['menus'],
    queryFn: async () => {
      const response = await fetch('/api/v1/menus/position/header');
      return response.json();
    }
  });

  // Fetch kategori berita
  const { data: categoryData } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await fetch('/api/v1/posts/categories');
      return response.json();
    }
  });

  const toggleMenu = (menuId) => {
    const newActiveMenus = new Set(activeMenus);
    if (newActiveMenus.has(menuId)) {
      newActiveMenus.delete(menuId);
    } else {
      newActiveMenus.add(menuId);
    }
    setActiveMenus(newActiveMenus);
  };

  // Recursive function to render menu items
  const renderMenuItem = (item) => {
    const isExternal = item.link?.match(/^http/);
    const hasChildren = item.children && item.children.length > 0;
    const isActive = activeMenus.has(item.id_menu);

    return (
      <li key={item.id_menu} className={`menu-item menu-item-has-children ${hasChildren ? 'second-lavel' : ''}`}>
        {isExternal ? (
          <a 
            href={item.link} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {item.nama_menu}
            {hasChildren && (
              <span className="nav-label">
                <i className="fas fa-chevron-down"></i>
              </span>
            )}
          </a>
        ) : (
          <a 
            href={item.link || '#'} 
            onClick={(e) => {
              if (hasChildren) {
                e.preventDefault();
                toggleMenu(item.id_menu);
              }
            }}
          >
            {item.nama_menu}
            {hasChildren && "  "}
          </a>
        )}
        {hasChildren && (
          <ul className="sub-menu">
            {item.children.map(child => (
              <li key={child.id_menu} className="menu-item menu-item-has-children">
                <a href={child.link || '#'}>{child.nama_menu}</a>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  // Filter root level menu items (those with id_parent = 0)
  const rootMenuItems = menuData?.data?.filter(item => item.id_parent === 0) || [];
  const categories = categoryData?.data || [];

  return (
    <ul className="nav-wrap inline-list d-flex align-items-center">
      {/* Main Navigation */}
      <li className="main-nav">
        <nav className="template-main-menu template-main-menu1">
          <ul className="menu">
            <li className="menu-item menu-item-has-children">
              <a 
                href="#" 
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu('main');
                }}
              >
                MENU
                <span className="nav-label">
                  <i className={`fas fa-chevron-${activeMenus.has('main') ? 'up' : 'down'}`}></i>
                </span>
              </a>
              {activeMenus.has('main') && (
                <ul className="sub-menu">
                  {rootMenuItems.map(item => renderMenuItem(item))}
                </ul>
              )}
            </li>
          </ul>
        </nav>
      </li>

      {/* Internal Links */}
      <li className="category-nav">
        <nav className="template-main-menu">
          <ul className="menu">
            <li className="menu-item menu-item-has-children">
              <a href="#" className="item-icon">
                <i className="fas fa-globe"></i>
                <span className="category-label">Internal Link</span>
              </a>
              <ul className="sub-menu">
                {links.map((link) => (
                  <li key={link.id} className="menu-item">
                    <a 
                      href={link.link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {link.nama_link}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </li>

      {/* Program Studi */}
      <li className="category-nav">
        <nav className="template-main-menu">
          <ul className="menu">
            <li className="menu-item menu-item-has-children">
              <Link to="/prodi" className="item-icon">
                <i className="fas fa-university"></i>
                <span className="category-label">Program Studi</span>
              </Link>
              <ul className="sub-menu">
                {studyPrograms.map((prodi) => (
                  <li key={prodi.id} className="menu-item">
                    <Link to={`/prodi/${prodi.id}`}>
                      {prodi.nama_program_studi}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </li>

      {/* Berita */}
      <li className="category-nav">
        <nav className="template-main-menu">
          <ul className="menu">
            <li className="menu-item menu-item-has-children">
              <Link to="/berita" className="item-icon">
                <i className="fas fa-newspaper"></i>
                <span className="category-label">Berita</span>
              </Link>
              <ul className="sub-menu">
                {categories.map((category) => (
                  <li key={category.id} className="menu-item">
                    <Link to={`/kategori/${category.post_kat_slug}`}>
                      {category.post_kat_nama}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </li>
    </ul>
  );
};

export default Menu;
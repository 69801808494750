import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from '@tanstack/react-query';
import { getImageProps } from '../helpers/storageHelper';
import { useSettings } from '../helpers/useSettings';

const About = () => {
  const { getSettingValue,institution } = useSettings();

  const { data: albumsData } = useQuery({
    queryKey: ['albums'],
    queryFn: async () => {
      const response = await fetch('/api/v1/albums');
      return response.json();
    }
  });

  const sliderAlbums = albumsData?.data?.data?.filter(album => album.is_slider === 1) || [];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: true
  };

  return (
    <section className="motion-effects-wrap about-wrap-style-1">
      <ul class="shape-wrap">
        <li class="shape-right motion-effects1">
          <img
            class="wow fadeInRight animated"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
            src="/media/elements/element58.png"
            alt="Element"
            width="171"
            height="189"
          />
        </li>
        <li class="shape-left">
          <img
            class="wow fadeInLeft animated"
            data-wow-duration="1s"
            data-wow-delay="1s"
            src="/media/elements/element58.png"
            alt="Element"
            width="171"
            height="189"
          />
        </li>
      </ul>
      <div class="container">
        <div class="row gutters-50">
          <div class="col-lg-6 col-12">
            <div class="about-box style-1">
              <h2 class="section-title">
                Mengapa harus kuliah di { institution.nama_perguruan_tinggi }
              </h2>
              <p class="section-description">
                {getSettingValue('system.slogan_instansi')}
              </p>
              <Slider {...settings}>
                {sliderAlbums.map((album) => (
                  <div key={album.id_album}>
                    <img
                      {...getImageProps(`albums/${album.foto_album}`, {
                        alt: album.judul_album,
                        width: 520,
                        height: 300,
                        defaultImage: '/default-image.jpg'
                      })}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div dangerouslySetInnerHTML={{ __html: getSettingValue('system.setting_4') }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
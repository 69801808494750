import React, { createContext, useContext, useState } from 'react';

const PreloaderContext = createContext();

export const PreloaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const showPreloader = () => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.style.display = '';  // Gunakan display default dari template
    }
    setLoading(true);
  };

  const hidePreloader = () => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.style.display = 'none';
    }
    setLoading(false);
  };

  // Efek untuk initialize preloader
  React.useEffect(() => {
    // Biarkan preloader template tampil sebentar saat pertama kali load
    setTimeout(hidePreloader, 500);
  }, []);

  return (
    <PreloaderContext.Provider value={{ loading, showPreloader, hidePreloader }}>
      {children}
    </PreloaderContext.Provider>
  );
};

export const usePreloader = () => useContext(PreloaderContext);
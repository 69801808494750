import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSettings } from '../helpers/useSettings';

const NavbarMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { getSettingValue, institution } = useSettings();

  // Fetch menu data
  const { data: menuData } = useQuery({
    queryKey: ['menus'],
    queryFn: async () => {
      const response = await fetch('/api/v1/menus/position/header');
      return response.json();
    }
  });

  // Build mobile menu recursively
  const buildMobileMenu = (menuItems) => {
    return menuItems?.map(item => (
      <li key={item.id_menu} className={item.id_parent === 0 ? 'menu-item' : 'menu-item menu-item-has-children'}>
        {item.link.match(/^http/) ? (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.nama_menu}
          </a>
        ) : (
          <Link to={item.link}>{item.nama_menu}</Link>
        )}
      </li>
    ));
  };

  return (
    <div className="rt-header-menu mean-container position-relative" id="meanmenu">
      <div className="topbar style-1">
        <div className="topbar-row for-mobile-menu">
          <ul className="left-box inline-list align-items-center justify-content-center w-100">
            <li>
              <i className="fas fa-phone-alt"></i> {institution.telepon}
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i> {institution.jalan}
            </li>
          </ul>
        </div>
      </div>

      <div className="mean-bar">
        <Link to="/">
          <img
            src={getSettingValue('system.app_logo')}
            alt={getSettingValue('system.app_name')}
            width="150"
          />
        </Link>
        <div className="mean-bar--right">
          <div className="actions search p-2">
            <a
              href="#template-search"
              className="btn btn-warning item-icon"
              style={{ fontSize: '12px' }}
              title="Search"
            >
              <i className="fas fa-university"></i> Link Penting
            </a>
          </div>
          <span 
            className="sidebarBtn"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </span>
        </div>
      </div>

      <div className={`rt-slide-nav${isMenuOpen ? ' active' : ''}`}>
        <div className="offscreen-navigation">
          <nav className="menu-main-primary-container">
            <ul className="menu">
              {menuData?.data && buildMobileMenu(menuData.data)}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
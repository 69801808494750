import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePreloader } from '../context/PreloaderContext';

const RouteChangeHandler = () => {
  const location = useLocation();
  const { showPreloader, hidePreloader } = usePreloader();

  useEffect(() => {
    showPreloader();
    setTimeout(hidePreloader, 500);
  }, [location, showPreloader, hidePreloader]);

  return null;
};

export default RouteChangeHandler;
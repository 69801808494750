// src/helpers/storageHelper.js

/**
 * Base URL dari environment variable Vite
 */
const APP_URL = import.meta.env.VITE_APP_URL || import.meta.env.APP_URL ;
const DEFAULT_IMAGE = '/default-image.jpg';

/**
 * Get storage image URL dengan fallback
 * @param {string} path - Path gambar dari storage
 * @returns {string} Full URL of the image
 */
export const getStorageUrl = (path) => {
  if (!path) return DEFAULT_IMAGE;
  return `${APP_URL}/storage/${path.replace(/^\/+/, '')}`;
};

/**
 * Get image props dengan error handling
 * @param {string} path - Path gambar dari storage
 * @param {Object} options - Additional options (className, alt, width, height, etc)
 * @returns {Object} Image props termasuk src dan error handler
 */
export const getImageProps = (path, options = {}) => {
  const { defaultImage = DEFAULT_IMAGE, ...rest } = options;
  
  return {
    src: getStorageUrl(path),
    onError: (e) => {
      e.target.src = defaultImage;
    },
    ...rest
  };
};

/**
 * Format file size dari bytes
 * @param {number} bytes - File size dalam bytes
 * @returns {string} Formatted file size
 */
export const formatFileSize = (bytes) => {
  if (!bytes) return '0 Bytes';
  
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

/**
 * Check if file exists di storage
 * @param {string} path - File path untuk dicek
 * @returns {Promise<boolean>} True if file exists
 */
export const checkFileExists = async (path) => {
  try {
    const response = await fetch(getStorageUrl(path), {
      method: 'HEAD'
    });
    return response.ok;
  } catch {
    return false;
  }
};
import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSettings } from '../../helpers/useSettings';

// Helper function for date formatting
const formatDate = (dateString) => {
  if (!dateString) return '';
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('id-ID', options);
};

// Skeleton loading component
const DetailSkeleton = () => (
  <div className="profile-box animate-pulse">
    <div className="content-box">
      <div className="flex justify-center mb-8">
        <div className="w-96 h-96 bg-gray-200 rounded"></div>
      </div>
      <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
      <div className="h-6 bg-gray-200 rounded w-1/2 mb-8"></div>
      <div className="space-y-4 mb-8">
        <div className="h-4 bg-gray-200 rounded"></div>
        <div className="h-4 bg-gray-200 rounded"></div>
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
    </div>
  </div>
);

const ProdiDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { useStudyProgram } = useSettings();
    
    const { data, isLoading, error, isError } = useStudyProgram(id);
  
    useEffect(() => {
      if (isError || (data?.status === 'error')) {
        navigate('/notfound');
      }
    }, [isError, data, navigate]);

    if (isLoading) {
        return (
          <section className="profile-wrap-style-1">
            <div className="container">
              <DetailSkeleton />
            </div>
          </section>
        );
    }
    
    const prodi = data?.data;
      
    return (
      <>
        <div className="breadcrumb-wrap-style-1">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/prodi">Prodi</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {prodi?.nama_program_studi}
              </li>
            </ol>
          </nav>
        </div>

        <section className="profile-wrap-style-1">
          <div className="container">
            <div className="profile-box">
              <div className="content-box">
                <div className="text-center mb-8">
                  <img
                    src={prodi?.logo_prodi || '/assets/image/favicon.png'}
                    alt={prodi?.nama_program_studi}
                    width="420"
                    height="420"
                    className="inline-block"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/assets/image/favicon.png';
                    }}
                  />
                </div>

                <h2 className="title">
                  ({prodi?.nama_jenjang_pendidikan}) {prodi?.nama_program_studi}
                </h2>

                <div className="d-flex align-items-center mb-4">
                  <div className="designation">{prodi?.kode_program_studi}</div>
                  <ul className="rating inline-list">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <li key={star}><i className="fas fa-star"></i></li>
                    ))}
                  </ul>
                </div>

                <p className="description">
                  {prodi?.akreditasi && (
                    <>AKREDITASI : {prodi.akreditasi}<br /></>
                  )}
                  {prodi?.tanggal_sk && (
                    <>TANGGAL BERDIRI : {formatDate(prodi.tanggal_sk)}<br /></>
                  )}
                  {prodi?.sk_penyelenggara && (
                    <>SK PENYELENGGARA : {prodi.sk_penyelenggara}<br /></>
                  )}
                  {prodi?.tanggal_sk && (
                    <>TANGGAL SK : {formatDate(prodi.tanggal_sk)}<br /></>
                  )}
                  {prodi?.about_prodi}
                </p>

                {prodi?.kompetensi_prodi && (
                  <>
                    <h3 className="sub-title">Kompetensi Prodi</h3>
                    <ul className="block-list">
                      <li dangerouslySetInnerHTML={{ __html: prodi.kompetensi_prodi }} />
                    </ul>
                  </>
                )}

                {prodi?.visi && (
                  <>
                    <h3 className="sub-title">Visi</h3>
                    <ul className="block-list">
                      <li dangerouslySetInnerHTML={{ __html: prodi.visi }} />
                    </ul>
                  </>
                )}

                {prodi?.misi && (
                  <>
                    <h3 className="sub-title">Misi</h3>
                    <ul className="block-list">
                      <li dangerouslySetInnerHTML={{ __html: prodi.misi }} />
                    </ul>
                  </>
                )}

                <h3 className="sub-title">Kontak Prodi</h3>
                <ul className="block-list address-list">
                  {prodi?.alamat && (
                    <li>
                      Alamat: <span dangerouslySetInnerHTML={{ __html: prodi.alamat }} />
                    </li>
                  )}
                  {prodi?.telpon && (
                    <li>Telepon: <span>{prodi.telpon}</span></li>
                  )}
                  {prodi?.email && (
                    <li>Email: <span>{prodi.email}</span></li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
};

export default ProdiDetail;
import { useQueries, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const RETRY_DELAY = 5000; // 5 seconds
const MAX_RETRIES = 2;

export const useSettings = () => {
  // Enhanced query config with retry logic
  const queryConfig = {
    retry: MAX_RETRIES,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, RETRY_DELAY),
    staleTime: 10 * 60 * 1000, // 10 minutes
    cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  };

  // Shared fetch function with error handling
  const fetchWithRetry = async (url) => {
    try {
      const response = await fetch(url, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      });

      if (response.status === 429) {
        // Get retry-after header or default to 5 seconds
        const retryAfter = parseInt(response.headers.get('Retry-After')) || 5;
        throw new Error(`Rate limited. Retry after ${retryAfter} seconds`);
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error(`Error fetching ${url}:`, error);
      throw error;
    }
  };

  // Memoized query definitions with enhanced error handling
  const queryDefinitions = useMemo(() => [
    {
      queryKey: ['settings'],
      queryFn: () => fetchWithRetry('/api/v1/settings'),
      ...queryConfig,
      onError: (error) => {
        console.error('Settings fetch error:', error);
      },
    },
    {
      queryKey: ['institution'],
      queryFn: () => fetchWithRetry('/api/v1/institution'),
      ...queryConfig,
      onError: (error) => {
        console.error('Institution fetch error:', error);
      },
    },
    {
      queryKey: ['links'],
      queryFn: () => fetchWithRetry('/api/v1/links'),
      ...queryConfig,
      onError: (error) => {
        console.error('Links fetch error:', error);
      },
    },
    {
      queryKey: ['study-programs'],
      queryFn: () => fetchWithRetry('/api/v1/study-programs'),
      ...queryConfig,
      onError: (error) => {
        console.error('Study programs fetch error:', error);
      },
    },
  ], []);

  const results = useQueries({
    queries: queryDefinitions,
  });

  const [settingsData, institutionData, linksData, studyProgramsData] = results;

  // Enhanced error state with detailed information
  const errors = useMemo(() => {
    return results
      .filter(result => result.error)
      .map(result => ({
        queryKey: result.queryKey[0],
        error: result.error,
      }));
  }, [results]);

  const isError = errors.length > 0;
  const isLoading = results.some(result => result.isLoading);

  // Memoized getter with error handling
  const getSettingValue = useMemo(() => (key) => {
    try {
      if (!settingsData.data?.data) return '';
      const [groupKey, settingKey] = key.split('.');
      const group = settingsData.data.data.find(group => group.key === groupKey);
      const setting = group?.settings?.find(s => s.key === settingKey);
      return setting ? setting.value : '';
    } catch (error) {
      console.error('Error in getSettingValue:', error);
      return '';
    }
  }, [settingsData.data]);

  // Enhanced study program fetching with retry logic
  const getStudyProgram = useMemo(() => async (id) => {
    return fetchWithRetry(`/api/v1/study-programs/${id}`);
  }, []);

  const useStudyProgram = (id) => {
    return useQuery({
      queryKey: ['study-program', id],
      queryFn: () => getStudyProgram(id),
      enabled: !!id,
      ...queryConfig,
    });
  };

  // Safe filtering with error handling
  const getFilteredStudyPrograms = useMemo(() => (filters = {}) => {
    try {
      if (!studyProgramsData.data?.data?.data) return [];
      
      let programs = studyProgramsData.data.data.data;

      if (filters.jenjang) {
        programs = programs.filter(p => p.nama_jenjang_pendidikan === filters.jenjang);
      }

      if (filters.status) {
        programs = programs.filter(p => p.status === filters.status);
      }

      if (filters.search) {
        const searchLower = filters.search.toLowerCase();
        programs = programs.filter(p => 
          p.nama_program_studi.toLowerCase().includes(searchLower) ||
          p.kode_program_studi.toLowerCase().includes(searchLower)
        );
      }

      return programs.sort((a, b) => 
        a.nama_jenjang_pendidikan.localeCompare(b.nama_jenjang_pendidikan)
      );
    } catch (error) {
      console.error('Error in getFilteredStudyPrograms:', error);
      return [];
    }
  }, [studyProgramsData.data]);

  return useMemo(() => ({
    isLoading,
    isError,
    errors, // Now returning detailed error information
    getSettingValue,
    getStudyProgram,
    useStudyProgram,
    getFilteredStudyPrograms,
    institution: institutionData.data?.data || {},
    links: linksData.data?.data?.data || [],
    studyPrograms: getFilteredStudyPrograms(),
    settingsData: settingsData.data,
    // Add refetch functions for manual retry
    refetchAll: () => results.forEach(result => result.refetch()),
    refetchSettings: () => settingsData.refetch(),
    refetchInstitution: () => institutionData.refetch(),
    refetchLinks: () => linksData.refetch(),
    refetchStudyPrograms: () => studyProgramsData.refetch(),
  }), [
    isLoading,
    isError,
    errors,
    getSettingValue,
    getStudyProgram,
    useStudyProgram,
    getFilteredStudyPrograms,
    institutionData.data,
    linksData.data,
    settingsData.data,
    results,
  ]);
};
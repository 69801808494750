import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

const POSTS_PER_PAGE = 9;

const PostCard = ({ post, formatDate }) => (
  <div className="bg-white rounded-lg shadow-md h-full">
    <div className="relative aspect-video overflow-hidden rounded-t-lg">
      <Link to={`/berita/${post.post_slug}`}>
        <img
          src={post.post_gambar ? `/storage/posts/${post.post_gambar}` : '/default-image.jpg'}
          alt={post.post_judul}
          className="object-cover w-full h-full transition-transform hover:scale-105"
          onError={(e) => {
            e.target.src = '/default-image.jpg';
          }}
        />
      </Link>
    </div>
    <div className="p-4">
      <Link 
        to={`/kategori/${post.kategori.post_kat_slug}`}
        className="text-sm font-medium text-blue-600 hover:text-blue-800"
      >
        {post.kategori.post_kat_nama}
      </Link>
      <h3 className="mt-2 text-xl font-semibold line-clamp-2">
        <Link 
          to={`/berita/${post.post_slug}`}
          className="hover:text-blue-600"
        >
          {post.post_judul}
        </Link>
      </h3>
      <div className="flex flex-wrap gap-4 mt-3 text-sm text-gray-600">
        <span className="flex items-center">
          <i className="fas fa-calendar-alt mr-2" />
          {formatDate(post.post_tanggal)}
        </span>
        <span className="flex items-center">
          <i className="far fa-eye mr-2" />
          {post.post_views} views
        </span>
        {post.post_komentar > 0 && (
          <span className="flex items-center">
            <i className="far fa-comments mr-2" />
            {post.post_komentar} comments
          </span>
        )}
      </div>
      <div className="mt-3 text-gray-700 line-clamp-3">
        <div 
          dangerouslySetInnerHTML={{ 
            __html: post.post_isi.length > 150 
              ? `${post.post_isi.substring(0, 150)}...`
              : post.post_isi 
          }} 
        />
      </div>
      <div className="mt-4 pt-4 border-t border-gray-200">
        <span className="text-sm font-medium text-gray-700">
          By {post.username.split('@')[0]}
        </span>
      </div>
    </div>
  </div>
);

const PostsSkeleton = () => (
  <>
    {[1, 2, 3].map((i) => (
      <div key={i} className="bg-white rounded-lg shadow-md h-full">
        <div className="aspect-video rounded-t-lg bg-gray-200 animate-pulse" />
        <div className="p-4">
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
          <div className="h-6 w-full mt-2 bg-gray-200 rounded animate-pulse" />
          <div className="h-4 w-3/4 mt-3 bg-gray-200 rounded animate-pulse" />
          <div className="flex gap-4 mt-3">
            <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>
      </div>
    ))}
  </>
);

const Posts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page')) || 1;
  const category = searchParams.get('category');
  const search = searchParams.get('search') || '';
  
  const handleSearch = React.useCallback((value) => {
    const timer = setTimeout(() => {
      setSearchParams(prev => {
        const newParams = new URLSearchParams(prev);
        if (value) {
          newParams.set('search', value);
        } else {
          newParams.delete('search');
        }
        newParams.set('page', '1');
        return newParams;
      });
    }, 300);
    
    return () => clearTimeout(timer);
  }, [setSearchParams]);

  const { data: postsData, isLoading, error } = useQuery({
    queryKey: ['posts', page, category, search],
    queryFn: async () => {
      try {
        let url = `/api/v1/posts?page=${page}&per_page=${POSTS_PER_PAGE}`;
        if (category) url += `&category=${category}`;
        if (search) url += `&search=${search}`;
        
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch posts');
        }
        return response.json();
      } catch (err) {
        throw new Error(err.message || 'An error occurred while fetching posts');
      }
    },
    keepPreviousData: true
  });

  const posts = postsData?.data?.data || [];
  const pagination = postsData?.data;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('id-ID', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  if (error) {
    return (
      <div className="container py-8">
        <div className="text-center text-red-600">
          <p>Error: {error.message}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <section className="py-12 bg-gray-50">
      <div className="container">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">Berita & Informasi</h2>
        </div>

        <div className="max-w-md mx-auto mb-8">
          <input
            type="text"
            placeholder="Cari berita..."
            defaultValue={search}
            onChange={(e) => handleSearch(e.target.value)}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {isLoading ? (
            <PostsSkeleton />
          ) : posts.length > 0 ? (
            posts.map((post) => (
              <PostCard 
                key={post.id}
                post={post}
                formatDate={formatDate}
              />
            ))
          ) : (
            <div className="col-span-full text-center py-8">
              <p className="text-gray-500">Tidak ada berita ditemukan</p>
            </div>
          )}
        </div>

        {pagination && pagination.last_page > 1 && (
          <nav className="mt-8 flex justify-center">
            <ul className="flex gap-2">
              {pagination.links.map((link, index) => (
                <li key={index}>
                  <button
                    className={`px-4 py-2 rounded ${
                      link.active
                        ? 'bg-blue-600 text-white'
                        : 'bg-white text-gray-700 hover:bg-gray-100'
                    } ${!link.url && 'opacity-50 cursor-not-allowed'}`}
                    onClick={() => {
                      if (link.url) {
                        const url = new URL(link.url);
                        const pageNum = url.searchParams.get('page');
                        setSearchParams(prev => {
                          const newParams = new URLSearchParams(prev);
                          newParams.set('page', pageNum);
                          return newParams;
                        });
                      }
                    }}
                    disabled={!link.url}
                    dangerouslySetInnerHTML={{ __html: link.label }}
                  />
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
};

export default Posts;
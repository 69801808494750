import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSettings } from '../../helpers/useSettings';

// Skeleton loading component
const ProgramSkeleton = () => (
  <div className="instructor-box style-1 animate-pulse">
    <div className="header-content">
      <div className="figure-box">
        <div className="w-24 h-24 bg-gray-200 rounded-full"></div>
      </div>
      <div className="content-box">
        <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-2/3"></div>
      </div>
    </div>
    <div className="footer-content">
      <div className="h-4 bg-gray-200 rounded w-full"></div>
    </div>
  </div>
);

const LoadingGrid = () => (
  <div className="row masonry-items">
    {[...Array(6)].map((_, index) => (
      <div key={index} className="col-xl-4 col-md-6 col-12 masonry-item">
        <ProgramSkeleton />
      </div>
    ))}
  </div>
);

const ProdiContent = () => {
  const { getSettingValue } = useSettings();
  const [visibleItems, setVisibleItems] = useState(6);
  
  const { data: studyProgramsData, isLoading, error } = useQuery({
    queryKey: ['study-programs'],
    queryFn: async () => {
      const result = await getSettingValue('study-programs');
      return result;
    }
  });

  if (isLoading) return <LoadingGrid />;
  
  if (error) {
    return (
      <div className="alert alert-danger">
        <p>Failed to load study programs. Please try again later.</p>
      </div>
    );
  }

  const studyPrograms = studyProgramsData?.data?.data || [];
  const visiblePrograms = studyPrograms.slice(0, visibleItems);
  const hasMore = visibleItems < studyPrograms.length;

  const loadMore = () => {
    setVisibleItems(prev => prev + 6);
  };

  return (
    <>
      <div className="row masonry-items">
        {visiblePrograms.map((program) => (
          <div key={program.id} className="col-xl-4 col-md-6 col-12 masonry-item">
            <div className="instructor-box style-1">
              <div className="header-content">
                <div className="figure-box">
                  <Link to={`/prodi/${program.id}`}>
                    <img
                      src={program.logo_prodi || '/assets/image/favicon.png'}
                      alt={program.nama_program_studi}
                      width="100"
                      height="100"
                      className="object-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/image/favicon.png';
                      }}
                    />
                  </Link>
                </div>
                <div className="content-box">
                  <h3 className="title">
                    <Link to={`/prodi/${program.id}`}>
                      ({program.nama_jenjang_pendidikan}) {program.nama_program_studi}
                    </Link>
                  </h3>
                  <h4 className="designation">{program.kode_program_studi}</h4>
                  <p className="qualification">{program.about_prodi}</p>
                  <ul className="rating inline-list">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <li key={star}><i className="fas fa-star"></i></li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="footer-content">
                <ul className="entry-meta inline-list">
                  {program.akreditasi && (
                    <li><i className="fas fa-award"></i>Akreditasi {program.akreditasi}</li>
                  )}
                  {program.email && (
                    <li><i className="far fa-envelope"></i>{program.email}</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>

      {hasMore && (
        <div className="load-more-btn text-center">
          <button 
            onClick={loadMore}
            type="button" 
            className="item-btn"
          >
            Load More Programs <i className="fas fa-angle-down"></i>
          </button>
        </div>
      )}
    </>
  );
};


const ProdiIndex = () => {
  const { institution, getSettingValue } = useSettings();


  const getImagePath = (path) => {
    return `../${path}`;
  }

  return (
    <>
      <div 
        className="breadcrumb-wrap-style-2"
        style={{ backgroundImage: `url(${getImagePath(getSettingValue('system.bg_post'))})` }}
      >
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Program Studi
            </li>
          </ol>
        </nav>
        <div className="inner-banner-title">
          <h1 className="title">Program Studi {institution.nama_perguruan_tinggi}</h1>
        </div>
      </div>

      <section className="instructor-wrap-style-1">
        <div className="container">
          <div className="section-heading style-10">
            <h2 className="title">Program Studi Tersedia</h2>
          </div>

          <ProdiContent />
        </div>
      </section>
    </>
  );
};

export default ProdiIndex;
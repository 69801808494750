// App.jsx (updated version)
import './bootstrap';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PreloaderProvider } from './Components/WebProfil/context/PreloaderContext';
import RouteChangeHandler from './Components/WebProfil/context/RouteChangeHandler';

// Components
import Header from './Components/WebProfil/Layout/Header';
import NavbarMobile from './Components/WebProfil/Layout/NavbarMobile';
import Footer from './Components/WebProfil/Layout/Footer';

// Pages
import Home from './Components/WebProfil/Pages/Home';
import Posts from './Components/WebProfil/Pages/Posts'; 
import PostDetail from './Components/WebProfil/Pages/PostDetail'; 
import Categories from './Components/WebProfil/Pages/Categories';
import Gallery from './Components/WebProfil/Pages/Gallery'; 
import Prodi from './Components/WebProfil/Pages/Prodi';
import ProdiDetail from './Components/WebProfil/Pages/ProdiDetail';
import NotFound from './Components/WebProfil/Pages/NotFound';

const queryClient = new QueryClient(); 

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <PreloaderProvider>
                    <RouteChangeHandler />
                    <div id="wrapper" className="wrapper">
                        <Header />
                        <NavbarMobile />
                        <main>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/berita" element={<Posts />} />
                                <Route path="/berita/:slug" element={<PostDetail />} />
                                <Route path="/halaman/:slug" element={<PostDetail />} />
                                <Route path="/kategori/:slug" element={<Categories />} />
                                <Route path="/prodi" element={<Prodi />} />
                                <Route path="/prodi/:id" element={<ProdiDetail />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </main>
                        <Footer />
                    </div>
                </PreloaderProvider>
            </Router>
        </QueryClientProvider>
    );
};

// Initialize React
const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Handle Template Search Modal
export const toggleSearch = (show = true) => {
    const templateSearch = document.getElementById('template-search');
    if (templateSearch) {
        templateSearch.style.display = show ? 'none' : 'block';
    }
};

window.toggleSearch = toggleSearch;
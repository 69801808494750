import React from 'react';
import { Link } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import { useSettings } from '../helpers/useSettings';

const Footer = () => {
  const { getSettingValue, institution, links } = useSettings();

  // Combine queries into one useQueries hook
  const [menuQuery, categoryQuery] = useQueries({
    queries: [
      {
        queryKey: ['menu-bottom'],
        queryFn: async () => {
          const response = await fetch('/api/v1/menus/position/footer');
          if (!response.ok) throw new Error('Failed to fetch menu');
          return response.json();
        },
        staleTime: 5 * 60 * 1000, // Cache for 5 minutes
        retry: 1
      },
      {
        queryKey: ['categories'],
        queryFn: async () => {
          const response = await fetch('/api/v1/posts/categories');
          if (!response.ok) throw new Error('Failed to fetch categories');
          return response.json();
        },
        staleTime: 5 * 60 * 1000,
        retry: 1
      }
    ]
  });

  // Derived state with proper error handling
  const menuBottom = menuQuery.data?.data || [];
  const categories = categoryQuery.data?.data || [];

  // Loading state
  if (menuQuery.isLoading || categoryQuery.isLoading) {
    return <div>Loading footer...</div>;
  }

  // Error state
  if (menuQuery.isError || categoryQuery.isError) {
    console.error('Footer error:', menuQuery.error, categoryQuery.error);
    // Return minimal footer on error
    return (
      <footer className="footer style-1">
        <div className="container">
          <div className="footer-bottom">
            <div className="footer-copyright text-center">
              © {new Date().getFullYear()} {institution.nama_perguruan_tinggi}
            </div>
          </div>
        </div>
      </footer>
    );
  }

   
  return (
    <footer className="footer style-1">
      <div className="container">
        <div className="footer-top">
          <div className="row">
            {/* Contact Info */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="widget widget-contact">
                <div className="footer-logo">
                  <Link to="/" className="d-inline-block">
                    <img 
                      src={getSettingValue('system.app_logo')} 
                      alt="Logo" 
                      width="250" 
                    />
                  </Link>
                </div>
                <div className="footer-address">
                {institution.jalan}   {institution.dusun}   {institution.nama_wilayah}
                </div>
                <div className="footer-number">
                  <i className="fas fa-phone-alt"></i> {institution.telepon}
                </div>
                <div className="footer-email">
                {institution.email}
                </div>
                <ul className="inline-list footer-social">
                  <li>
                    <a href={getSettingValue('facebook_link')} className="facebook">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href={getSettingValue('twitter_link')} className="twitter">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={getSettingValue('instagram_link')} className="instagram">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={getSettingValue('youtube_link')} className="youtube">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Links */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="widget widget-nav style-1">
                <h3 className="widget-heading heading-light">Link</h3>
                <ul className="block-list menu">
                  {links.map((link) => (
                    <li key={link.id} className="menu-item">
                      <a href={link.link} target="_blank" rel="noopener noreferrer">
                        {link.nama_link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Categories */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="widget widget-nav style-1">
                <h3 className="widget-heading heading-light">Kategori</h3>
                <ul className="block-list menu">
                  {categories.map((category) => (
                    <li key={category.id} className="menu-item">
                      <Link to={`/kategori/${category.post_kat_slug}`}>
                        {category.post_kat_nama}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Biro Akademik */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="widget widget-nav style-1">
                <h3 className="widget-heading heading-light">Biro Akademik!</h3>
                <ul className="block-list menu">
                  {menuBottom.map((menu) => (
                    <li key={menu.id_menu} className="menu-item">
                      <Link to={menu.link}>{menu.nama_menu}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="row">
            <div className="col-lg-6 col-12 d-flex justify-content-center justify-content-lg-start align-items-center text-center">
              <div className="footer-copyright">
                © {new Date().getFullYear()} <span>{institution.nama_perguruan_tinggi}</span> by{' '}
                <Link to="/">{getSettingValue('system.developer')}</Link>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex justify-content-center justify-content-lg-end align-items-center text-center">
              <div className="footer-terms">
                <Link to="/halaman/privacy-policy">Privacy Policy</Link>
                <Link to="/halaman/term-of-use">Terms of Use</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
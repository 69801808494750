import { useEffect } from 'react';
import { useSettings } from './useSettings';

export const useSeo = ({ 
  title, 
  description, 
  keywords,
  image,
  type = 'website',
  author,
  publishedTime,
  modifiedTime,
  section,
  tags = [],
  locale = 'id_ID'
}) => {
  const { getSettingValue } = useSettings();

  useEffect(() => {
    // Get default values from settings
    const defaultTitle = getSettingValue('system.app_name');
    const defaultDescription = getSettingValue('system.meta_description');
    const defaultKeywords = getSettingValue('system.meta_keywords');
    const websiteUrl = getSettingValue('system.website_url');
    const logoUrl = getSettingValue('system.logo');
    const defaultImage = getSettingValue('system.meta_image');
    const defaultAuthor = getSettingValue('system.meta_author');
    const twitterUsername = getSettingValue('system.twitter_username');
    const fbAppId = getSettingValue('system.facebook_app_id');

    // Helper function to create or update meta tags
    const setMetaTag = (name, content, attribute = 'name') => {
      if (!content) return;
      
      const meta = document.querySelector(`meta[${attribute}="${name}"]`) || document.createElement('meta');
      meta.setAttribute(attribute, name);
      meta.setAttribute('content', content);
      if (!document.querySelector(`meta[${attribute}="${name}"]`)) {
        document.head.appendChild(meta);
      }
    };

    // Helper function to create or update link tags
    const setLinkTag = (rel, href) => {
      if (!href) return;
      
      const link = document.querySelector(`link[rel="${rel}"]`) || document.createElement('link');
      link.setAttribute('rel', rel);
      link.setAttribute('href', href);
      if (!document.querySelector(`link[rel="${rel}"]`)) {
        document.head.appendChild(link);
      }
    };

    // Format title
    const formattedTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
    document.title = formattedTitle;

    // Basic Meta Tags
    setMetaTag('description', description || defaultDescription);
    setMetaTag('keywords', keywords || defaultKeywords);
    setMetaTag('author', author || defaultAuthor);
    setMetaTag('robots', 'index, follow');
    setMetaTag('language', locale.split('_')[0]);

    // OpenGraph Meta Tags
    setMetaTag('og:title', formattedTitle, 'property');
    setMetaTag('og:description', description || defaultDescription, 'property');
    setMetaTag('og:type', type, 'property');
    setMetaTag('og:url', `${websiteUrl}${window.location.pathname}`, 'property');
    setMetaTag('og:image', image || defaultImage, 'property');
    setMetaTag('og:site_name', defaultTitle, 'property');
    setMetaTag('og:locale', locale, 'property');
    if (fbAppId) {
      setMetaTag('fb:app_id', fbAppId, 'property');
    }

    // Article Specific Meta Tags
    if (type === 'article') {
      setMetaTag('article:published_time', publishedTime, 'property');
      setMetaTag('article:modified_time', modifiedTime, 'property');
      setMetaTag('article:section', section, 'property');
      setMetaTag('article:author', author || defaultAuthor, 'property');
      tags.forEach((tag, index) => {
        setMetaTag(`article:tag${index ? index : ''}`, tag, 'property');
      });
    }

    // Twitter Card Meta Tags
    setMetaTag('twitter:card', 'summary_large_image');
    setMetaTag('twitter:title', formattedTitle);
    setMetaTag('twitter:description', description || defaultDescription);
    setMetaTag('twitter:image', image || defaultImage);
    if (twitterUsername) {
      setMetaTag('twitter:site', twitterUsername);
      setMetaTag('twitter:creator', twitterUsername);
    }

    // Canonical URL
    setLinkTag('canonical', `${websiteUrl}${window.location.pathname}`);

    // JSON-LD Structured Data
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': type === 'article' ? 'Article' : 'WebPage',
      headline: title,
      description: description || defaultDescription,
      image: image || defaultImage,
      url: `${websiteUrl}${window.location.pathname}`,
      datePublished: publishedTime,
      dateModified: modifiedTime,
      author: {
        '@type': 'Organization',
        name: author || defaultAuthor,
        url: websiteUrl
      },
      publisher: {
        '@type': 'Organization',
        name: defaultTitle,
        url: websiteUrl,
        logo: {
          '@type': 'ImageObject',
          url: logoUrl
        }
      }
    };

    // Add or update JSON-LD script
    let scriptTag = document.querySelector('script[type="application/ld+json"]');
    if (!scriptTag) {
      scriptTag = document.createElement('script');
      scriptTag.type = 'application/ld+json';
      document.head.appendChild(scriptTag);
    }
    scriptTag.textContent = JSON.stringify(structuredData);

    // Cleanup function
    return () => {
      if (scriptTag) {
        scriptTag.remove();
      }
    };
  }, [
    title,
    description,
    keywords,
    image,
    type,
    author,
    publishedTime,
    modifiedTime,
    section,
    tags,
    locale,
    getSettingValue
  ]);
};

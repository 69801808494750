import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery, useQueries } from '@tanstack/react-query'; 
import { useSettings } from '../helpers/useSettings';
import { getImageProps } from '../helpers/storageHelper';

const Sidebar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { links } = useSettings();

  // Filter links
  const quickLinks = links.filter(link => link.type === 'link' && link.posisi === 'sidebar');
  const bannerLinks = links.filter(link => link.type === 'image' && link.posisi === 'sidebar');

  // Combine all queries into one useQueries hook
  const [categoriesQuery, latestNewsQuery] = useQueries({
    queries: [
      {
        queryKey: ['categories'],
        queryFn: async () => {
          const response = await fetch('/api/v1/posts/categories');
          if (!response.ok) throw new Error('Failed to fetch categories');
          return response.json();
        },
        staleTime: 5 * 60 * 1000, // Cache for 5 minutes
        retry: 1
      },
      {
        queryKey: ['latest-news'],
        queryFn: async () => {
          const response = await fetch('/api/v1/posts?type=post&per_page=5');
          if (!response.ok) throw new Error('Failed to fetch latest news');
          return response.json();
        },
        staleTime: 1 * 60 * 1000, // Cache for 1 minute
        retry: 1
      }
    ]
  });

  // Find announcement category and fetch announcements
  const announcementCategory = categoriesQuery.data?.data?.find(
    cat => cat.post_kat_slug === 'pengumuman'
  );
  
  const announcementsQuery = useQuery({
    queryKey: ['category-posts', announcementCategory?.id],
    queryFn: async () => {
      if (!announcementCategory?.id) return null;
      const response = await fetch(
        `/api/v1/posts?post_kategori_id=${announcementCategory.id}&per_page=5`
      );
      if (!response.ok) throw new Error('Failed to fetch announcements');
      return response.json();
    },
    enabled: !!announcementCategory?.id,
    staleTime: 2 * 60 * 1000, // Cache for 2 minutes
    retry: 1
  });

  // Handle loading states
  const isLoading = [categoriesQuery, latestNewsQuery, announcementsQuery].some(
    query => query.isLoading
  );

  // Handle error states
  const isError = [categoriesQuery, latestNewsQuery, announcementsQuery].some(
    query => query.isError
  );

  if (isLoading) {
    return <div>Loading sidebar...</div>;
  }

  if (isError) {
    console.error('Sidebar errors:', {
      categories: categoriesQuery.error,
      latestNews: latestNewsQuery.error,
      announcements: announcementsQuery.error
    });
    return null; // Or minimal error UI
  }

  // Derived state
  const categories = categoriesQuery.data?.data || [];
  const announcements = announcementsQuery.data?.data?.data || [];
  const latestNews = latestNewsQuery.data?.data?.data || [];

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('id-ID', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/berita?search=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  return (
    <div className="col-lg-4">
      <div className="sidebar-widget">
        {/* Search Widget */}
        <div className="widget">
          <div className="widget-search">
            <div className="widget-section-heading heading-dark">
              <h3 className="widget-title">Search</h3>
            </div>
            <form onSubmit={handleSearch}>
              <div className="input-group stylish-input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cari"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <span className="input-group-addon">
                  <button type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>

        {/* Filtered Announcements Widget */}
        <div className="widget">
          <div className="widget-recent">
            <div className="widget-section-heading heading-dark">
              <h3 className="widget-title">Pengumuman</h3>
            </div>
            {announcements.length > 0 ? (
              <ul className="block-list list-item">
                {announcements.map((item) => (
                  <li className="media" key={item.id}>
                    <div className="left-box">
                      <Link to={`/berita/${item.post_slug}`} className="item-figure">
                        <img
                          {...getImageProps(`posts/${item.post_gambar}`, {
                            alt: item.post_judul,
                            width: 100,
                            height: 80,
                            defaultImage: '/default-image.jpg'
                          })}
                        />
                      </Link>
                    </div>
                    <div className="media-body">
                      <ul className="inline-list entry-meta">
                        <li className="entry-date">
                          <Link to={`/berita/${item.post_slug}`}>
                            <i className="far fa-calendar-alt"></i>
                            {formatDate(item.created_at)}
                          </Link>
                        </li>
                      </ul>
                      <h3 className="entry-title">
                        <Link to={`/berita/${item.post_slug}`}>
                          {item.post_judul}
                        </Link>
                      </h3>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-center py-4">Tidak ada pengumuman saat ini.</p>
            )}
          </div>
        </div>

        {/* Quick Links Widget */}
        {quickLinks.length > 0 && (
          <div className="widget">
            <div className="widget-course-category">
              <div className="widget-section-heading heading-dark">
                <h3 className="widget-title">Link Cepat</h3>
              </div>
              <ul className="block-list list-item">
                {quickLinks.map((link) => (
                  <li className="menu-item" key={link.id}>
                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                      <i className="fas fa-link"></i> {link.nama_link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* Categories Widget */}
        <div className="widget">
          <div className="widget-course-category">
            <div className="widget-section-heading heading-dark">
              <h3 className="widget-title">Kategori berita</h3>
            </div>
            <ul className="block-list list-item">
              {categories.map((category) => (
                <li className="menu-item" key={category.id}>
                  <Link to={`/kategori/${category.post_kat_slug}`}>
                    <i className="fas fa-folder"></i> {category.post_kat_nama} 
                    <span className="category-count">({category.posts_count})</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Latest News Widget */}
        <div className="widget">
          <div className="widget-recent">
            <div className="widget-section-heading heading-dark">
              <h3 className="widget-title">Berita terbaru</h3>
            </div>
            <ul className="block-list list-item">
              {latestNews.map((item) => (
                <li className="media" key={item.id}>
                  <div className="left-box">
                    <Link to={`/berita/${item.post_slug}`} className="item-figure">
                      <img
                        {...getImageProps(`posts/${item.post_gambar}`, {
                          alt: item.post_judul,
                          width: 100,
                          height: 80,
                          defaultImage: '/default-image.jpg'
                        })}
                      />
                    </Link>
                  </div>
                  <div className="media-body">
                    <ul className="inline-list entry-meta">
                      <li className="entry-date">
                        <Link to={`/berita/${item.post_slug}`}>
                          <i className="far fa-calendar-alt"></i>
                          {formatDate(item.created_at)}
                        </Link>
                      </li>
                    </ul>
                    <h3 className="entry-title">
                      <Link to={`/berita/${item.post_slug}`}>
                        {item.post_judul}
                      </Link>
                    </h3>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Banner Widgets */}
        {bannerLinks.map((banner) => (
          <div className="widget p-0" key={banner.id}>
            <div className="widget-banner">
              <a href={banner.link} target="_blank" rel="noopener noreferrer" title={banner.nama_link}>
                <img
                  {...getImageProps(`links/${banner.gambar}`, {
                    alt: banner.nama_link,
                    width: 309,
                    height: 290
                  })}
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
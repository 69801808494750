import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { truncateHtmlText } from '../../helpers/textHelpers';
import { getStorageUrl, getImageProps } from '../../helpers/storageHelper';
import { useSettings } from '../../helpers/useSettings';
import About from '@/Components/WebProfil/Layout/About';
import StudyProgramsSection from '@/Components/WebProfil/Layout/StudyProgramsSection';
import BrandSection from '@/Components/WebProfil/Layout/BrandSection';

const Home = () => {
  const { getSettingValue,institution } = useSettings();

  const { data: sambutanData, isLoading: isSambutanLoading } = useQuery({
    queryKey: ['sambutan'],
    queryFn: async () => {
      const response = await fetch('/api/v1/posts/pages/sambutan');
      if (!response.ok) throw new Error('Failed to fetch sambutan');
      return response.json();
    }
  });

  const sambutan = sambutanData?.data;
  const sambutanText = sambutan?.post_isi ? truncateHtmlText(sambutan.post_isi, 50, '...') : '';

  // Check if PMB is active
  const isPmbActive = getSettingValue('pmb.is_active') === 'Active';
  
  // Loading state
  if (isSambutanLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  // Format date helper
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('id-ID', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <>
     <section className="main-banner-wrap-style-1 motion-effects-wrap">
      <div className="right-full-width">
        <div className="main-banner-box style-1">
          <div className="content-box">
            <div className="sub-title">Selamat datang</div>
            <h1 className="title">{ institution.nama_perguruan_tinggi }</h1>
            
            {isPmbActive ? (
              <>
                <p className="description">
                { institution.nama_perguruan_tinggi } telah membuka pendaftaran mahasiswa/i baru tahun ajaran {getSettingValue('pmb.tahun_akademik')}.
                </p>
                <ul className="inline-list info-list">
                  <li className="color-1">
                    <i className="fas fa-check-circle"></i>
                    Dibuka {formatDate(getSettingValue('pmb.tanggal_buka'))}
                  </li>
                  <li className="color-2">
                    <i className="fas fa-check-circle"></i>
                    Sampai {formatDate(getSettingValue('pmb.tanggal_tutup'))}
                  </li>
                </ul>
                <a 
                  className="btn-download" 
                  href={getSettingValue('system.link_penmaru', '#')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-users"></i>
                  Daftar Sekarang
                </a>
              </>
            ) : (
              <p className="description">
                {getSettingValue('system.app_desc', 'Selamat datang di website kampus kami')}
              </p>
            )}
          </div>

          <div className="element-box">
            <img
              {...getImageProps(getSettingValue('system.bg_belakang_sambutan'), {
                className: "wow fadeInRight animated",
                'data-wow-duration': "1s",
                'data-wow-delay': "0.1s",
                alt: "Banner",
                width: "486",
                height: "626",
                defaultImage: '/webprofil-template/media/banner/banner11.png'
              })}
            />
            
            <span className="main-bottom-shape">
              <img
                className="wow fadeInRight animated"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
                src="/webprofil-template/media/banner/banner12.png"
                alt="Banner"
                width="486"
                height="626"
              />
            </span>

            {sambutan && (
              <div className="course-box">
                <div
                  className="wow fadeInLeft animated"
                  data-wow-duration="1s"
                  data-wow-delay="0.7s"
                >
                  <div className="figure-box">
                    <Link to={`/halaman/${sambutan.post_slug}`}>
                    <img
                        {...getImageProps('posts/'+sambutan.post_gambar, {
                            alt: '"Sambutan"',
                            width: "325",
                            height: "169",
                            defaultImage: '/webprofil-template/media/banner/sambutan.png'
                        })}
                    />
                    </Link>
                    <div className="price">{sambutan.post_judul}</div>
                  </div>
                  <div className="content-box">
                    <p>{sambutanText}</p>
                  </div>
                </div>
              </div>
            )}

            <a
              href="/contact"
              className="chat-btn wow zoomIn animated"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <i className="fas fa-comments"></i>
            </a>

            <ul className="block-list shape-list">
              <li className="shape-1 motion-effects1">
                <img
                  className="wow fadeInDown animated"
                  data-wow-duration="1s"
                  data-wow-delay="1.1s"
                  src="/webprofil-template/media/elements/element45.png"
                  alt="Element"
                  width="115"
                  height="127"
                />
              </li>
              <li className="shape-2 motion-effects3">
                <img
                  className="wow fadeInUp animated"
                  data-wow-duration="1s"
                  data-wow-delay="1.3s"
                  src="/webprofil-template/media/elements/element46.png"
                  alt="Element"
                  width="322"
                  height="322"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <About />
    <StudyProgramsSection />
    <BrandSection />
    </>
  );
};

export default Home;